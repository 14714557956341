import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        color={"ms"}
        current_step={2}
        lesson={"Lesson 3"}
        module_title="Will You Buy What They Are Selling?"
        total_count={8}
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_3_step_2.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>A goal of advertising is to get more people to buy the company's product or to believe in the cause they are promoting, often by using specific strategies.</p>
          <p>In the next steps, you and your teammates will learn about one of these strategies and then share with your classmates.</p>
          <p>Your teacher will assign a number to your team. Click on your number below and follow the directions.</p>

          <div className="flex flex-wrap">
            <div className="flex flex-wrap">
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_1.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 1"
                >
                  1
              </Button>
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_2.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 2"
                >
                  2
              </Button>
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_3.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 3"
                >
                  3
              </Button>
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_4.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 4"
                >
                  4
              </Button>
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_5.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 5"
                >
                  5
              </Button>
              <Button
                action="secondary"
                className="m-2"
                color="purple"
                href="/files/hs/lesson_3/pursuasion_strategy_6.aw.pdf"
                icon="download"
                style={{ width: "152px" }}
                title="Pursuasion Strategy 6"
                >
                  6
              </Button>
            </div>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
